import { time } from "react-timeline";


type StringMap = {
    [key: string]: string;
};

interface IStringIndexable {
    [key: string]: any
}


export type DashiConfig = IStringIndexable & {
    CampusColors: StringMap;
    client: string;
    colorBy: StringMap;
    legend?: StringMap;
    designPhaseColor: string;
    userGuide?: string;
    product: string;
    project: string;
    source?: string;
    contact?: string;
    escalation: number;
    periodScale: number;
    startYear: number;
    timelineBlockHeight: number;
    timelineRowPadding: number;
    totalPeriods: number;
    phases?: { [key: string]: [number, number] }
    whitelist?: string[];
};

const DefaultDashiConfig: DashiConfig = {
    CampusColors: {
        "Main Campus": '#30378E',
    },
    colorBy: {
        type: 'ProjectTypeColors',
        campus: 'CampusColors',
    },
    client: 'Dashi by Sasaki',
    designPhaseColor: '#c5dbd6',
    product: 'DASHI Implementation Planning Dashboard by Sasaki',
    project: 'N/A',
    periodScale: time.QUARTER,
    escalation: .04,

    startYear: 2020,
    timelineBlockHeight: 20, // px
    timelineRowPadding: 2, // px
    totalPeriods: (2 * 10 * 4) + 24,
};


export default DefaultDashiConfig;
