import {useCallback, useEffect, useState} from 'react';
import {Group, Input, Label, RadioSlider, Select, TextArea} from '@strategies/react-form';
import {time} from 'react-timeline';
import {observer} from 'mobx-react';
import Modal from '@strategies/react-modal';

import {useDashiStores} from '../../../../core';
import TrashIcon from '../../../../core/ui/icons/Trash';
import {duration} from '../../../../core/util';
import {FINISH_LEVELS} from '../../models/Project';
import {SpecialProjectType} from '../../models/SpecialProject';
import { roundCost } from '../../util';

import HardCutActive from '../../assets/images/HardCut-Active-01.jpg';
import HardCutPassive from '../../assets/images/HardCut-Preserve-01.jpg';
import SoftCutActive from '../../assets/images/SoftCut-Active-01.jpg';
import SoftCutPassive from '../../assets/images/SoftCut-Preserve-01.jpg';
import TunnelActive from '../../assets/images/Tunnel-Active-01.jpg';
import TunnelPassive from '../../assets/images/Tunnel-Preserve-01.jpg';
import ViaductActive from '../../assets/images/Viaduct-Active-01.jpg';
import ViaductPassive from '../../assets/images/Viaduct-Preserve-01.jpg';


const AXONS = {
    "Hard Cut": {
        active: HardCutActive,
        passive: HardCutPassive,
    },
    "Soft Cut": {
        active: SoftCutActive,
        passive: SoftCutPassive,
    },
    "Viaduct": {
        active: ViaductActive,
        passive: ViaductPassive,
    },
    "Tunnel": {
        active: TunnelActive,
        passive: TunnelPassive,
    },
};

const INFRASTRUCTURE_TEXT = 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];


export default observer(function ProjectSettings() {
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const {app, config, persist} = useDashiStores();
    const {selectedProject: project, ui} = app;

    useEffect(() => setDeleteConfirmation(false), [ui.projectSettingsIsOpen]);

    const cancel = useCallback(() => {
        if (project.snapshots.length > 0) {
            project.undo();
        } else { // fresh project
            persist.remove(project);
        }
        ui.setProjectSettingsOpen(false);
    }, [persist, project, ui]);

    if (!ui.projectSettingsIsOpen || !project) {
        return <></>;
    }

    const {design, start, end} = project.timestamps;

    return <>
        <div className={`ProjectSettings ${ui.projectSettingsIsOpen && project ? 'active' : ''}`}>
            <div className="ProjectSettings-gutter" onClick={() => ui.setProjectSettingsOpen(false)}/>

            <div className="ProjectSettings-window">
                <div className="ProjectSettings-header">
                    Segment Data
                </div>

                {project && (
                    <div className="ProjectSettings-form">
                        <section className="top">
                            <Group>
                                <Input name="Project Name" className="project-name" value={project.name}
                                       onChange={val => project.setName(val)}/>
                                {project.isSpecialProject || (
                                    <Group name="Project Length" className="hide-labels">
                                        <Input readonly={true} value={project.mileLength} left="~" right={'Mile'}/>
                                    </Group>
                                )}
                            </Group>
                        </section>

                        {project.isSpecialProject || <>
                            <section className="info">
                                <Group>
                                    <Input name="Type" value={project.type} readonly={true}/>
                                    <Input name="Neighborhood" value={project.neighborhood} readonly={true}/>
                                    <Input name="Location" value={project.location} readonly={true}/>
                                </Group>

                                <Group>
                                    <Input name="Ownership" value={project.ownership || '-'} readonly={true}/>
                                    <Input name="Other" value={project.other || '-'} readonly={true}/>
                                </Group>
                            </section>

                            <section className="infrastructure">
                                <Group name="Infrastructure">
                                    <div className="cost-sibling" />
                                    <Input name="Cost" type={'number'} value={roundCost(project.infrastructure)} left={'$'}
                                           readonly={true}/>
                                </Group>
                            </section>

                            <section className="access">
                                <Group>
                                    <div className="cost-sibling">
                                        <Label name="Access Option">
                                            <AccessOption option={'Elevator'}/>
                                            <AccessOption option={'Ramp'}/>
                                            <AccessOption option={'Stairs'}/>

                                            {project.groundCost !== null && (
                                                <AccessOption option={'Ground'}/>
                                            )}
                                        </Label>
                                    </div>
                                    <Input name="Access Cost" type={"number"} value={roundCost(project.accessCost)} left={'$'}
                                           readonly={true}/>
                                </Group>
                            </section>

                            <section className="landscape">
                                <Group>
                                    <div className="cost-sibling">
                                        <RadioSlider
                                            name="Landscape Finish"
                                            className="secondary"
                                            value={project.finish_level}
                                            options={Object.keys(FINISH_LEVELS)}
                                            onChange={val => project.setFinishLevel(val)}
                                        />

                                        <div className="finish-axons">
                                            <img src={AXONS[project.type].passive} alt="" />
                                            <img src={AXONS[project.type].active} alt="" />
                                        </div>
                                    </div>
                                    <Input name="Cost" type={"number"} value={roundCost(project.landscapeFinishCost)} left={'$'}
                                           readonly={true}/>
                                </Group>

                                <Group>
                                    <div className="cost-sibling"/>
                                    <Input name="Project Cost" type="number" value={roundCost(project.totalCost)} left="$"
                                           readonly={true}/>
                                </Group>
                            </section>
                        </>}

                        {project.isSpecialProject && (
                            <section className="special-project-info">
                                <Group>
                                    <div className="cost-sibling">
                                        <Select
                                            name="Project Type"
                                            options={Object.values(SpecialProjectType)}
                                            value={project.type}
                                            onChange={val => project.setType(val)}
                                        />
                                    </div>
                                    <Input type="number" readonly={true} name="Project Cost" value={roundCost(project.totalCost)}
                                           left="$"/>
                                </Group>
                            </section>
                        )}

                        <section className="fundraising">
                            <Group>
                                <div className="cost-sibling">
                                    <Input name="Fundraising Benchmark" type="number" value={persist.fundraising}
                                           readonly={true} left="$" right="Goal"/>
                                    <button onClick={() => ui.setGlobalSettingsModalOpen()}>
                                        Change
                                    </button>
                                </div>

                                <Input left="%" readonly={true}
                                       value={(100 * project.totalCost / persist.fundraising).toFixed(2)}/>
                            </Group>
                        </section>

                        <section className="timestamps">
                            <Group>
                                <Group name="Design Start">
                                    <Input className="month" name="Month" htmlFor="design-month" labelPosition='bottom'
                                           value={months[Math.abs(Math.floor(design / time.MONTH)) % 12]}
                                           readonly={true}/>
                                    <Input className="year" name="Year" htmlFor="design-year" labelPosition='bottom'
                                           value={Math.floor(design / time.YEAR) + config.startYear} readonly={true}/>
                                    <Input className="quarter" name="Quarter" htmlFor="design-quarter"
                                           labelPosition='bottom'
                                           value={`Q${Math.floor((Math.abs(design / time.MONTH) % 12) / 3) + 1}`}
                                           readonly={true}/>
                                </Group>
                                <Input className="duration" name="Duration" htmlFor="design-duration" type="increment"
                                       right="Months" value={duration(design, start)}
                                       onChange={value => project.setTimestamps({design: start - (((isNaN(value) ? 0 : value)) * time.MONTH)})}/>
                            </Group>

                            <Group>
                                <Group name="Construction Start">
                                    <Select
                                        className="month"
                                        name="Month"
                                        htmlFor="start-month"
                                        labelPosition='bottom'
                                        options={months}
                                        value={months[Math.abs(Math.floor(start / time.MONTH)) % 12]}
                                        onChange={month => {
                                            const yearStart = start - (start % time.YEAR);
                                            const newStart = yearStart + (months.indexOf(month) * time.MONTH)
                                            const delta = start - newStart;

                                            project.setTimestamps({
                                                design: design - delta,
                                                start: newStart,
                                                end: end - delta,
                                            });
                                        }}
                                    />

                                    <Input
                                        className="year"
                                        name="Year"
                                        type="increment"
                                        htmlFor="start-year"
                                        labelPosition='bottom'
                                        value={Math.floor(start / time.YEAR) + config.startYear}
                                        onChange={val => {
                                            const years = (val - config.startYear) * time.YEAR;
                                            const months = start % time.YEAR;
                                            const newStart = years + months;
                                            const delta = start - newStart;

                                            project.setTimestamps({
                                                design: design - delta,
                                                start: newStart,
                                                end: end - delta,
                                            });
                                        }}
                                    />

                                    <Input className="quarter" name="Quarter" htmlFor="start-quarter"
                                           labelPosition='bottom'
                                           value={`Q${Math.floor((Math.abs(start / time.MONTH) % 12) / 3) + 1}`}
                                           readonly={true}/>
                                </Group>
                                <Input className="duration" name="Duration" htmlFor="start-duration" type="increment"
                                       right="Months" value={duration(start, end)}
                                       onChange={value => project.setTimestamps({end: start + (((isNaN(value) ? 0 : value)) * time.MONTH)})}/>
                            </Group>

                            <Group name="Project Completion">
                                <Input className="month" name="Month" htmlFor="end-month" labelPosition='bottom'
                                       value={months[Math.abs(Math.floor(end / time.MONTH)) % 12]} readonly={true}/>
                                <Input className="year" name="Year" htmlFor="end-year" labelPosition='bottom'
                                       value={Math.floor(end / time.YEAR) + config.startYear} readonly={true}/>
                                <Input className="quarter" name="Quarter" htmlFor="end-quarter" labelPosition='bottom'
                                       value={`Q${Math.floor((Math.abs(end / time.MONTH) % 12) / 3) + 1}`}
                                       readonly={true}/>
                            </Group>
                        </section>
                    </div>
                )}

                <div className="ProjectSettings-footer">
                    <button className="delete-project" onClick={() => setDeleteConfirmation(true)}>
                        <TrashIcon/>
                    </button>
                    <button className="secondary" onClick={cancel}>Cancel</button>
                    <button className="primary" onClick={() => ui.setProjectSettingsOpen(false)}>Done</button>
                </div>
            </div>
        </div>

        <Modal className={`delete-project-confirmation ${deleteConfirmation ? 'active' : ''}`}
               onClose={() => setDeleteConfirmation(false)}>
            <header>Delete Project</header>

            <div className="content">
                <p>Are you sure you want to delete {project.name}?</p>

                <div className="modal-buttons">
                    <button className="secondary" onClick={() => setDeleteConfirmation(false)}>Cancel</button>

                    <button
                        className="warn"
                        onClick={() => {
                            ui.setProjectSettingsOpen(false);
                            persist.remove(project);
                        }}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </Modal>
    </>;

});

var AccessOption = observer(function AccessOption({option}) {
    const {selectedProject: project} = useDashiStores().app;

    return (
        <Group className="AccessOption">
            <label htmlFor={`access[${option}]`}>{option}</label>

            <Input
                type="number"
                left="$"
                value={roundCost(project[`${option.toLowerCase()}Cost`] * project.escalation)}
                readonly={true}
            />

            <Input
                htmlFor={`access[${option}]`}
                type="increment"
                left={'X'}
                value={project[option.toLowerCase()]}
                onChange={val => project[`set${option}`](val)}
            />
        </Group>
    );
});
