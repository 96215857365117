import { action, computed, observable, makeObservable } from 'mobx';
import { Block, time } from 'react-timeline';

import { getRootStore } from '..';
import DashiStore from './DashiStore';
import { randomString } from '../util';
import DashiChart from '../ui/charts/Chart';
import DashiMetric from '../models/Metric';
import DashiPeriod from '../models/Period';
import DashiUIStore from './UIStore';
import DashiLayoutStore from "./LayoutStore";
import DashiProject, {IProjectData, TimelineStatus} from '../models/Project';


const MONTH = 10;


type MetricMap = {
    [key: string]: DashiMetric;
}

type FilterState = { [key: string]: string[] };

export type DashiAppStoreStores = {
    ui?: DashiUIStore;
    layout?: DashiLayoutStore;
};

export default class DashiAppStore extends DashiStore {
    ui: DashiUIStore;
    layout: DashiLayoutStore;
    metrics: MetricMap = {};

    constructor({ ui, layout }: DashiAppStoreStores) {
        super();

        makeObservable(this);

        this.ui = ui || new DashiUIStore();
        this.layout = layout || new DashiLayoutStore();
    }

    onAttachedToRootStore() {
        for (let i = 0; i < getRootStore().config.totalPeriods; i++) {
            this.periods.push(new DashiPeriod(i));
        }

        this.triggerBlockSort();
    }

    charts: typeof DashiChart[] = [];

    @observable
    colorMode: string = 'type';

    @action
    setColorMode(mode: string) {
        this.colorMode = mode;
    }

    @observable
    timelineEnabled: boolean = false;

    @action
    setTimelineEnabled(value: boolean) {
        this.timelineEnabled = value;
    }

    @observable
    periods: DashiPeriod[] = [];

    @observable
    scrubber: number = MONTH * time.MONTH;

    @action
    setScrubber(scrubber: number) {
        this.scrubber = scrubber;
    }

    @observable
    activeBySelection: boolean = false;

    @action
    setActiveBySelection(bySelection: boolean = true) {
        this.activeBySelection = bySelection;
    }

    @computed
    get activeProjects() {
        return this.activeBySelection ? this.selectedProjects : this.projects.filter(project => !project.isExcluded && project.timelineStatus === TimelineStatus.Completed);
    }


    @computed
    get includedProjects() {
        return this.projects.filter(project => !project.isExcluded)
    }

    @computed
    get blocks() {
        const blocks: (typeof Block)[] = [];

        this.includedProjects.forEach((project: any) => {
            const [block, designBlock] = project.blocks;

            blocks.push(block);
            blocks.push(designBlock);
        });

        return blocks;
    }

    @computed
    get currentPeriod() {
        const { periodScale } = getRootStore().config;

        let period = Math.floor(this.scrubber / periodScale);

        if (period < 0) {
            period = 0;
        }
        else if (period >= this.periods.length) {
            period = this.periods.length - 1;
        }

        return this.periods[period];
    }

    @observable
    filters: FilterState = {};

    @action
    setFilters(filters: FilterState) {
        this.filters = filters;
        this.triggerBlockSort();
    }

    @computed
    /**
     *  @returns IDashiProject[] - only projects that pass the filter criteria.
     *  Use persist.projects to return all projects
     */
    get projects() {
        const { persist } = getRootStore();

        return persist.projects.filter(project => {
            let filtered = true;

            if (project.type === "Existing") {//TODO REFACTOR: move this logic to skin
                filtered = false;
            }
            else {
                Object.entries(this.filters).some(([attr, values]) => {
                    // @ts-ignore
                    if (values.indexOf(project[attr]) !== -1) {
                        filtered = false;
                    }
                    return !filtered;
                });
            }

            return filtered;
        });
    }

    createProject(data: IProjectData) {
        return new DashiProject(data);
    }

    createProjectFromBlock(block: typeof Block) {
        block.remove();

        return this.createProject({
            id: randomString(20),
            name: 'New Project',
            type: "New Construction",
            mapped: false,
            isExcluded: false,
            metrics: Object.keys(this.metrics).reduce((metrics: any, metric: string) => {
                metrics[metric] = this.metrics[metric].generatePayload()
                return metrics;
            }, {}),
            timelineY: block.y,
            timestamps: {
                design: block.start,
                start: block.start + (time.MONTH * 6),
                end: block.start + (time.MONTH * 18),
            }
        });
    }

    @action
    selectAndEditProject(projectToSelect: DashiProject) {
        this.projects.forEach((project: DashiProject) => {
            project.setSelected(projectToSelect === project);
        });
        this.selectedProject.snapshot();
        this.ui.setProjectSettingsOpen(true);
    }

    @action
    selectProject(projectToSelect: DashiProject, augmentSelection:boolean = false) {
        if(augmentSelection) {
            projectToSelect.setSelected(!projectToSelect.selected);
            return;
        }
        this.projects.forEach((project: DashiProject) => {
            project.setSelected(projectToSelect === project);
        });
    }

    @computed
    get projectsKey() {
        return this.includedProjects.map((project: DashiProject )=> project.$modelId).reduce((a: string, b: string) => a + b, '');
    }

    @computed
    get selectedProjects() {
        return getRootStore().persist.projects.filter((project: DashiProject) => project.selected);
    }

    @computed
    get selectedProject() {
        return this.selectedProjects[0];
    }

    @computed
    get periodLabels() {
        return this.periods.map(period => period.label);
    }

    triggerBlockSort() {
        const { config } = getRootStore();

        [ ...this.includedProjects ].sort((a,b) => this.sortBlocks(a,b)).forEach((project, i) => {
            project.setTimelineY(i * (config.timelineBlockHeight + config.timelineRowPadding));
        });
    }

    sortBlocks(a: DashiProject, b: DashiProject) {
        return a.timestamps.start === b.timestamps.start ? 0 : a.timestamps.start < b.timestamps.start ? -1 : 1;
    }

}
