import {observer} from "mobx-react";

import {useDashiStores} from '../../index';
import {fixedRectStyle} from "../../stores/LayoutStore";

export default observer(function SelectionInfo({info, buttons, title}) {
    const {layout} = useDashiStores().app;

    return (
        <div className="SelectionInfo" style={fixedRectStyle(layout.selectionInfoRect)}>
            <div className="panel">
                <div className="title-bar">
                    {title}
                </div>

                <div className="content">
                    <div className="info">
                        {info.map(({name, value}) => (
                            <div key={name} className={'row'}>
                                <div className={'name'}>
                                    {name}
                                </div>
                                <div className={'value'}>
                                    {value}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className={'buttons'}>
                        {buttons.map(({label, onClick}) =>
                            <button key={label} className="secondary" onClick={onClick}>
                                {label}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});
