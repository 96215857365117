import DashiColorizerStore from '../../../core/stores/ColorizerStore';
import DashiColorizerCanvas, {DashiColorizerCanvasProps} from '../../../core/models/ColorizerCanvas';

import Asset_Colorizer_Icons_Graphic from '../assets/colorizer/icons/graphic.jpg';
import Asset_Colorizer_Icons_Id from '../assets/colorizer/icons/id.png';
import Asset_Colorizer_Icons_Key from '../assets/colorizer/icons/id.png-keyIndex.json';

import Asset_Colorizer_Iso5_Data from '../assets/colorizer/iso_5/data.png';
import Asset_Colorizer_Iso5_Graphic from '../assets/colorizer/iso_5/output.png';
import Asset_Colorizer_Iso5_Key from '../assets/colorizer/iso_5/data.png-keyIndex.json';

import Asset_Colorizer_Iso10_Data from '../assets/colorizer/iso_10/data.png';
import Asset_Colorizer_Iso10_Graphic from '../assets/colorizer/iso_10/output.png';
import Asset_Colorizer_Iso10_Key from '../assets/colorizer/iso_10/data.png-keyIndex.json';

import Asset_Colorizer_Sections_Data from '../assets/colorizer/sections/data.png';
import Asset_Colorizer_Sections_Graphic from '../assets/colorizer/sections/output.png';
import Asset_Colorizer_Sections_Key from '../assets/colorizer/sections/data.png-keyIndex.json';

import Asset_Colorizer_Base_Image from '../assets/colorizer/Existing_Base.jpg';
import Asset_Colorizer_Overlay_Image from '../assets/colorizer/Existing_Base_Leaders.png';

import {ColorizerLayer, GraphicIdLayer, ImageLayer} from "../../../core/models/ColorizerImageLayer";
import {computed, makeObservable, override} from "mobx";
import {getRootStore} from "../../../core";
import DashiColorizerState from "../../../core/models/Colorizer";
import DashiProject, {TimelineStatus} from "../../../core/models/Project";
import RailsProject from "../models/Project";
import RailsAppStore from "./AppStore";

export class Iso5ColorizerLayer extends ColorizerLayer {
    constructor(imageWidth: number, imageHeight: number, graphic: string, data: string, key: string[]) {
        super(imageWidth, imageHeight, graphic, data, key);
        makeObservable(this);
    }

    @computed get isVisible(): boolean {
        const {outputs} = (getRootStore().app as RailsAppStore);
        return outputs.isoTime === '5';
    }

    @override
    get colorizerStates(): Map<string, DashiColorizerState> {
        if (!this.isVisible) return new Map<string, DashiColorizerState>();
        const states = new Map<string, DashiColorizerState>();
        this._colorizerStates.forEach((v, k) => {
            if (!v.includedInCharts) return;
            //Note: filteredOut has its own specific graphic treatment that we don't want for isochrones
            states.set(k, new DashiColorizerState({...v, color: '#000000', filteredOut:false}));
        });
        return states;
    }

}

export class Iso10ColorizerLayer extends Iso5ColorizerLayer {
    constructor(imageWidth: number, imageHeight: number, graphic: string, data: string, key: string[]) {
        super(imageWidth, imageHeight, graphic, data, key);
        makeObservable(this);
    }

    @override get isVisible(): boolean {
        const {outputs} = (getRootStore().app as RailsAppStore);
        return outputs.isoTime === '10';
    }
}

export class IconsGraphicIdLayer extends GraphicIdLayer {
    constructor(imageWidth: number, imageHeight: number, graphic: string, id: string, key: string[]) {
        super(imageWidth, imageHeight, graphic, id, key);
        makeObservable(this);
    }

    @override
    get colorizerStates(): Map<string, DashiColorizerState> {
        const {persist} = getRootStore();
        const states = new Map<string, DashiColorizerState>();
        const {projects} = persist;//Note: this is the full project list (not filtered)

        projects.forEach((project: DashiProject) => {
            const railsProject = project as RailsProject;
            if (railsProject) {
                if (railsProject.isExcluded || railsProject.timelineStatus === TimelineStatus.NotStarted) return;
                if (railsProject.elevator > 0) states.set(project.id + '_e', project.colorizerState);
                if (railsProject.stairs > 0) states.set(project.id + '_s', project.colorizerState);
                if (railsProject.ground > 0) states.set(project.id + '_g', project.colorizerState);
                if (railsProject.ramp > 0) states.set(project.id + '_r', project.colorizerState);
            }
        });
        return states;
    }

}


class RailsDashiColorizerCanvas extends DashiColorizerCanvas {
    constructor(props: DashiColorizerCanvasProps) {
        super(props);
        makeObservable(this);
    }

    @override
    get allProjectIds(): string[] {
        return Asset_Colorizer_Sections_Key
    }
}

export default class RailsColorizerStore extends DashiColorizerStore {

    constructor() {
        super();

        const width = 3066;
        const height = 2000;
        this.setStageSize(width, height);

        this.addCanvas(new RailsDashiColorizerCanvas({
            title: 'Rail Park',
            width,
            height,
            layers: [
                new ImageLayer(Asset_Colorizer_Base_Image, width, height),
                new Iso5ColorizerLayer(width, height,
                    Asset_Colorizer_Iso5_Graphic,
                    Asset_Colorizer_Iso5_Data,
                    Asset_Colorizer_Iso5_Key,
                ),
                new Iso10ColorizerLayer(width, height,
                    Asset_Colorizer_Iso10_Graphic,
                    Asset_Colorizer_Iso10_Data,
                    Asset_Colorizer_Iso10_Key,
                ),
                new ImageLayer(Asset_Colorizer_Overlay_Image, width, height),
                new IconsGraphicIdLayer(width, height,
                    Asset_Colorizer_Icons_Graphic,
                    Asset_Colorizer_Icons_Id,
                    Asset_Colorizer_Icons_Key,
                ),
                new ColorizerLayer(width, height,
                    Asset_Colorizer_Sections_Graphic,
                    Asset_Colorizer_Sections_Data,
                    Asset_Colorizer_Sections_Key,
                ),
            ]
        }));

    }

}
