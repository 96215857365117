import { computed, makeObservable } from 'mobx';

import { getRootStore } from '..';
import { MetricPayload } from './Metric';
import DashiCell from './Cell';


export default class DashiPeriod {

    i: number;

    constructor(i: number) {
        makeObservable(this);
        this.i = i;
    }

    @computed
    get label() {
        const { startYear } = getRootStore().config;
        return `'${startYear - 2000 + Math.floor(this.i / 4)}`;//TODO check if this should be i+1
    }

    @computed
    get cells() {
        return getRootStore().cells.elements.filter((cell: DashiCell) => cell.period === this.i);
    }

    /*
    @computed
    get totals() {
        const totals: { [key: string]: MetricPayload } = {};

        this.cells.forEach(cell => {
            const values = { ...cell.value };//cell.value is wrapped as mobx proxy and is not iterable with Object.keys

            if (!(cell.metric.name in totals)) {
                totals[cell.metric.name] = {};
            }

            Object.keys(values).forEach(key => {
                if (!(key in totals[cell.metric.name])) {
                    totals[cell.metric.name][key] = 0;
                }

                totals[cell.metric.name][key] += values[key];
            });
        });

        return totals;
    }
    */


    @computed
    get timeStamp() {
        const {config} = getRootStore();
        return config.periodScale * this.i;
    }

}
