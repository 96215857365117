import { computed, makeObservable } from 'mobx';

import DashiProject from './Project';
import DashiMetric, { MetricBehavior } from './Metric';


export default class DashiCell {

    project: DashiProject;
    period: number;

    constructor(project: DashiProject, period: number) {
        makeObservable(this);

        this.project = project;
        this.period = period;
    }

    @computed
    get behavior() {
        if (this.project.periods.start <= this.period)  {
            if (this.project.periods.end >= this.period) {
                return MetricBehavior.During;
            }

            return MetricBehavior.After;
        }

        return MetricBehavior.Before;
    }

    /*
    @computed
    get payload(): any {
        return this.project.metrics[this.metric.name];
    }

    @computed
    get value() {
        return this.metric.getValue(
            this.behavior,
            this.payload,
            this.period - this.project.periods.start
        );
    }
    */

}
