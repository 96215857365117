const firebaseConfig = {
    apiKey: "AIzaSyBVfMivdcRJsU64jQP3reM5dJtggMCZuy4",
    authDomain: "dashi-rail-park-demo.firebaseapp.com",
    projectId: "dashi-rail-park-demo",
    storageBucket: "dashi-rail-park-demo.appspot.com",
    messagingSenderId: "271175373195",
    appId: "1:271175373195:web:fa232ae671b15aa1b41f8e"
};

export default firebaseConfig;
