// @ts-nocheck
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import merge from 'lodash.merge';

import DashiChart from './Chart';
import ChartHeader from './ChartHeader/ChartHeader';


ChartJS.register(...registerables);


export default class HorizontalBarChart extends DashiChart {

    render() {
        const { style } = this.props;

        const data = {
            labels: Object.keys(this.data[0]),
            datasets: [
                {
                    type: 'bar',
                    data: Object.values(this.data[0]),
                    backgroundColor: this.colors.value,
                }
            ],
            axes: {x: {title: this.x}, y: {title: this.y}}
        };

        const options = {
            indexAxis: 'y',
            plugins: {
                legend: {
                    display: false,
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    title: {
                        text: this.x,
                        display: true,
                    },
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                },
                y: {
                    title: {
                        text: this.y,
                        display: true,
                    },
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                },
            },
        };

        return (
            <div className="DashiChart HorizontalBarChart" style={style}>
                <ChartHeader title={this.static.title}/>

                <div>
                    <Chart
                        data={data}
                        options={merge(options, this.options)}
                    />
                </div>
            </div>
        );
    }

}
