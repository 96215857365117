import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Modal from '@strategies/react-modal';

import { useDashiStores } from '../..';


export default observer(function SaveAsModal() {
    const { app, persist } = useDashiStores();
    const [scenarioName, setScenarioName] = useState(persist.scenarioName);
    const [description, setDescription] = useState(persist.description);

    useEffect(() => {
        setScenarioName(persist.scenarioName);
    }, [persist.scenarioName])

    const saveAs = useCallback(() => {
        persist.setScenarioName(scenarioName);
        persist.setDescription(description);

        persist.branch();
        persist.save();

        app.ui.setSaveAsModalOpen(false);
    }, [app.ui, persist, scenarioName, description]);

    const cancel = useCallback(() => {
        setScenarioName(persist.scenarioName);
        setDescription(persist.description);

        app.ui.setSaveAsModalOpen(false);
    }, [app.ui, persist.scenarioName, persist.description, setScenarioName, setDescription]);

    return (
        <Modal className={`SaveAsModal ${app.ui.saveAsModalIsOpen ? 'active' : ''}`} onClose={() => cancel()}>
            <header>Save As</header>

            <div className="content">
                <div className='input'>
                    <label htmlFor="SaveScenarioName">Scenario Name</label>
                    <input
                        id="SaveScenarioName"
                        placeholder="Name your new scenario ..."
                        value={scenarioName}
                        onChange={e => setScenarioName(e.target.value)}
                    />
                </div>

                <div className='input'>
                    <label htmlFor="SaveDescription">Description</label>
                    <input
                        id="SaveDescription"
                        placeholder="Describe this scenario ..."
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                    />
                </div>

                <div className="modal-buttons">
                    <button className="secondary" onClick={cancel}>Cancel</button>

                    <button
                        className={`primary ${scenarioName === '' || description === '' ? 'disabled' : ''}`}
                        onClick={saveAs}
                    >
                        Create Scenario
                    </button>
                </div>
            </div>
        </Modal>
    );
});
