import {observer} from 'mobx-react';
import {useDashiStores} from '../../../core';

import Login from './Login';
import MenuBar from '../../philly-rails/ui/MenuBar';
import ChartsPanel from '../../philly-rails/ui/ChartsPanel';
import WelcomeModal from '../../philly-rails/ui/WelcomeModal';
import ProjectSettings from '../../philly-rails/ui/ProjectSettings';
import SpecialProjects from '../../philly-rails/ui/SpecialProjects';
import GlobalSettingsModal from '../../philly-rails/ui/GlobalSettingsModal';
import DisplaySelectionInfo from "../../philly-rails/ui/DisplaySelectionInfo";
import FileModal from './../../../core/ui/FileModal';
import InfoModal from './../../../core/ui/InfoModal';
import SaveAsModal from './../../../core/ui/SaveAsModal';
import TimelinePanel from './../../../core/ui/TimelinePanel';
import LockedChartPanel from './../../../core/ui/LockedChartPanel';
import ColorizerStage from './../../../core/ui/Colorizer/components/ColorizerStage';

export default observer(function DashiApp() {
    const {app, user} = useDashiStores();

    return (
        <div className="DashiApp">
            {!(user.isLoggedIn && user.isVerified) ? (
                <Login/>
            ) : <>
                <WelcomeModal/>
                <InfoModal/>
                <SaveAsModal/>
                <FileModal/>
                <GlobalSettingsModal/>

                <ColorizerStage/>
                <ProjectSettings/>
                <SpecialProjects/>

                <DisplaySelectionInfo/>

                {app.ui.timelineIsLocked ? (
                    <LockedChartPanel/>
                ) : (
                    <div className="panels">
                        <MenuBar/>
                        <TimelinePanel/>
                        <ChartsPanel/>
                    </div>
                )}
            </>}
        </div>
    );
});
