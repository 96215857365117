import merge from 'lodash.merge';
import { Doughnut } from "react-chartjs-2";

import DashiChart from './Chart';
import ChartHeader from "./ChartHeader/ChartHeader";


export default class PieChart extends DashiChart {

    cutout: string = '0%';

    static aspect = 1;

    render() {
        const { style } = this.props;

        const values = Object.keys(this.colors).map(type => this.data[0][type] || 0);
        const currentTypes = Object.entries(this.data[0]).reduce((types: string[], [type, value]) => value > 0 ? [ ...types, type ] : types, []);

        const header = {
            datasets: currentTypes.map(label => ({ label, backgroundColor: this.colors[label] })),
        };

        const data = {
            datasets: [{
                data: values,
                backgroundColor: Object.values(this.colors),
            }],

            labels: Object.keys(this.colors),
        };

        return (
            <div className="DashiChart PieChart" style={style}>
                <ChartHeader data={header} title={this.static.title} />

                <div>
                    <Doughnut
                        data={data}
                        options={merge({
                            cutout: this.cutout,
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    display: false,
                                },
                            },
                        }, this.options)}
                    />
                </div>
            </div>
        );
    }

}
