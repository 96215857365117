import SelectionInfo from "../../../core/ui/SelectionInfo";
import {observer} from "mobx-react";
import {useDashiStores} from "../../../core";
import {formatDollars} from "../../../core/ui/TimelinePanel/ProjectList";
import Fraction from "fraction.js";


export default observer(function DisplaySelectionInfo() {
    const {app, persist} = useDashiStores();
    const {ui} = app;
    const panelTitle = "Selection Overview";

    if (ui.queuedSpecialProject) {
        return <SelectionInfo
            title={'Create Special Project'}
            info={[
                {name: 'Name', value: ui.queuedSpecialProject}
            ]}
            buttons={[
                {
                    label: 'Create Project',
                    onClick: () => {
                        const project = app.createSpecialProject(ui.queuedSpecialProject);
                        persist.addProject(project);
                        app.selectProject(project);
                        ui.queueSpecialProject(null);
                        ui.setProjectSettingsOpen(true);
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                        ui.queueSpecialProject(null);
                    }
                }
            ]}
        />
    }

    if (!app.selectedProject) return null;

    if (app.selectedProjects.length > 1) {
        const selectedAndExcluded = app.selectedProjects.filter((project) => project.isExcluded);
        const info = [
            {name: 'Selected', value: `${app.selectedProjects.length}`},
        ];
        let totalCost = 0;
        let totalLength = new Fraction(0);
        app.selectedProjects.forEach((project, i) => {
            totalCost += project.totalCost
            if (project.mileLength) totalLength = totalLength.add(new Fraction(project.mileLength));
        });

        if (totalCost > 0) {
            info.push({name: 'Cost', value: `${formatDollars(totalCost)}`},);
        }
        console.log(totalLength, totalLength.valueOf());

        if (totalLength.valueOf() > 0) {
            info.push({name: 'Length', value: `${totalLength.toFraction(true)} Mile`},);
        }

        const buttons = [];
        if (selectedAndExcluded.length > 0) {
            if (selectedAndExcluded.length !== app.selectedProjects.length) {
                info.push({name: 'Excluded', value: `${selectedAndExcluded.length}`},);
            }
            buttons.push({
                label: 'Add (' + selectedAndExcluded.length + ') Selected',
                onClick: () => {
                    selectedAndExcluded.forEach((project, i) => {
                        project.setIsExcluded(false);
                    });
                }
            });
        }
        return <SelectionInfo
            title={panelTitle}
            info={info}
            buttons={buttons}
        />;
    }

    if (app.selectedProject.isSpecialProject) {
        return <SelectionInfo
            title={panelTitle}
            info={[
                {name: 'Name', value: `${app.selectedProject.name}`},
                {name: 'Type', value: `${app.selectedProject.type}`},
            ]}
            buttons={[
                {
                    label: 'View Data',
                    onClick: () => {
                        app.selectedProject.snapshot();
                        app.ui.setProjectSettingsOpen(true);
                    }
                }
            ]}
        />;
    } else {
        const info = [
            {name: 'Segment', value: app.selectedProject.segment},
            {name: 'Length', value: `${app.selectedProject.mileLength} MILE`},
            {name: 'Cost', value: `${formatDollars(app.selectedProject.totalCost)}`},
            {name: 'Location', value: app.selectedProject.name},
        ];
        if (app.selectedProject.filteredOut) {
            info.push({name: 'Filtered out', value: 'by '+app.selectedProject.reasonWhyFilteredOut});
        }
        return <SelectionInfo
            title={panelTitle}
            info={info}
            buttons={[
                {
                    label: 'View Data',
                    onClick: () => {
                        app.selectedProject.snapshot();
                        app.ui.setProjectSettingsOpen(true);
                    }
                },
                ... app.selectedProject.filteredOut ? [] : [{
                    label: app.selectedProject.isExcluded ? 'Add' : 'Remove',
                    onClick: () => {
                        app.selectedProject.setIsExcluded(!app.selectedProject.isExcluded);
                    }
                }]
            ]}
        />
    }
});
