import {useState} from 'react';
import {observer} from "mobx-react";
import {time} from 'react-timeline';
// @ts-ignore
import Slider from 'rc-slider';

import {useDashiStores} from '../..';
import PlayCircleIcon from '../icons/PlayCircle';
import StopCircleIcon from '../icons/StopCircle';


export default observer(function TimeSlider() {
    const {app, config} = useDashiStores();
    const {ui} = app;

    const [animation, setAnimation] = useState<NodeJS.Timeout | undefined>(undefined);

    const min = 0;
    const max = app.periods.length * config.periodScale;
    const step = time.MONTH;
    const scrubberVal = app.scrubber < min ? min : (app.scrubber > max ? max : app.scrubber);
    let increasing = true;

    const scrubberMonth = () => {
        return Math.floor(scrubberVal / time.MONTH) % 12;
    }

    const scrubberYear = () => {
        return Math.floor(scrubberVal / time.YEAR) + config.startYear;
    }

    const onSliderClick = () => {
        if (animation !== undefined) {
            clearInterval(animation);
            setAnimation(undefined);
        }
    }

    const toggleAnimation = () => {
        if (animation !== undefined) {
            clearInterval(animation);
            setAnimation(undefined);
        } else {
            let interval = setInterval(() => {

                let time = app.scrubber + (increasing ? step : -step);
                if (time > max) {
                    time = max - step;
                    increasing = false;
                } else if (time < min) {
                    time = min + step;
                    increasing = true;
                }
                app.setScrubber(time);

            }, 400);
            setAnimation(interval);
        }
    }

    return (
        <div className={`TimeSlider ${!ui.timelinePanelIsOpen ? 'sliderClosed' : ''}`}>
            <div className="title">
                <div className="time-number">{time.months[scrubberMonth()] + " " + scrubberYear()}</div>
            </div>

            <div className="slider-comp" onClick={onSliderClick}>
                <Slider
                    min={min}
                    max={max}
                    value={app.scrubber}
                    onChange={v => app.setScrubber(v > 1 ? v : 1)}
                />
            </div>

            <button onClick={toggleAnimation}>
                {animation ? <StopCircleIcon /> : <PlayCircleIcon />}
            </button>
        </div>
    );
});
