import { action, computed, observable, makeObservable, reaction } from 'mobx';

import { getRootStore } from '..';
import DashiStore from './DashiStore';
import DashiAppStore from '../stores/AppStore';
import DashiCell from '../models/Cell';


export default class DashiCellStore extends DashiStore {

    @computed
    get elements() {
        const { app } = getRootStore();

        return observable(
            app.projects.map(project => (
                // @ts-ignore
                app.periods.map(period => (
                    new DashiCell(project, period.i)
                ))
            )).reduce((a, b) => [ ...a, ...b ], [])
        );
    }

    constructor() {
        super();
        makeObservable(this);
    }

}
