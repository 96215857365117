import {action, computed, observable} from 'mobx';

import {getRootStore} from '..';
import DashiStore from './DashiStore';
import DashiProject from '../models/Project';
import DashiColorizerCanvas from '../models/ColorizerCanvas';
import DashiColorizerState from '../models/Colorizer';


export interface IDashiColorizerStoreSet {
    [key: string]: Map<string, DashiColorizerState>;
}

export default class DashiColorizerStore extends DashiStore {

    @observable
    scale: number = 0.8;

    @observable
    height: number = 800;

    @observable
    width: number = 600;

    @observable
    canvases: DashiColorizerCanvas[] = [];

    @computed
    get colorizers() {
        const states = new Map<string, DashiColorizerState>();
        const {projects} = getRootStore().persist;

        projects.filter((project: DashiProject) => project.visible).forEach((project: DashiProject) => {
            states.set(project.id, project.colorizerState);
        });

        return states;
    }

    @computed
    get colorizersByCanvas(): IDashiColorizerStoreSet {
        const states: IDashiColorizerStoreSet = {};
        const {projects} = getRootStore().persist;//Note: this is the full project list (not filtered)

        //existing buildings are not listed in the projects data, but should show up on the canvas
        const allProjectIds: any = {};

        projects.forEach((project: DashiProject) => {
            allProjectIds[project.id] = project;
        });

        this.canvases.forEach((canvas) => {
            states[canvas.key] = new Map<string, DashiColorizerState>();
            //TODO apply colorizer states?
            // canvas.assets.building.key.forEach((k) => {
            //     if (!allProjectIds[k]) {
            //         states[canvas.key].set(k, new DashiColorizerState({}));
            //     }
            // });
        });

        projects.filter((project: DashiProject) => project.visible).forEach((project: DashiProject) => {
            const canvasIds = this.canvasesByProject[project.id] || [];
            canvasIds.forEach(canvasId => {
                if (states[canvasId]) {
                    states[canvasId].set(project.id, project.colorizerState);
                }
            });
        });

        return states;
    }


    @computed
    get canvasesByProject() {
        const ans: { [key: string]: string[] } = {};
        Object.keys(this.projectsByCanvas).forEach((k) => {
            const arr = this.projectsByCanvas[k];
            arr.forEach(projId => {
                if (!ans[projId]) {
                    ans[projId] = [];
                }
                ans[projId].push(k);
            });
        });
        return ans;
    }

    @computed
    get projectsByCanvas() {//TODO update for new assets structure or push to skins?
        return (
            this.canvases
                .map(canvas => ({
                    [canvas.key]: canvas.allProjectIds
                }))
                .reduce((a, b) => ({...a, ...b}), {})
        );
    }

    @action
    setScale(scale: number) {
        this.scale = scale;
    }

    @action
    setStageSize(width: number, height: number) {
        this.width = width;
        this.height = height;
    }

    @action
    addCanvas(canvas: DashiColorizerCanvas) {
        this.canvases.push(canvas);
    }

    @observable
    imageIdx: number = 0;

    @action
    setImageIdx(imageIdx: number) {
        this.imageIdx = imageIdx;
    }

    emitProjectClick(id: string | null, ctrlKey: boolean, shiftKey: boolean) {
        const {persist} = getRootStore();

        persist.projects.forEach((project: DashiProject) => {
            if (project.id === id) {
                project.setSelected(!project.selected);
            } else {
                if (!ctrlKey) {
                    project.setSelected(false);
                }
            }
        });
    }

}
