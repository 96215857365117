import { observer } from 'mobx-react';

import { getRootStore } from '../../../../core';
import DashiChart, {DashiChartProps} from '../../../../core/ui/charts/Chart';

import RailsAppStore from '../../stores/AppStore';
import MetricView from "../../../../core/models/MetricView";
import {IRailsProject} from "../../models/Project";
import {makeObservable, observable} from "mobx";
import {roundCost} from "../../util";


@observer
class Summary extends DashiChart {
    @observable
    private costView: MetricView;
    constructor(props: DashiChartProps) {
        super(props);
        this.costView = new MetricView((p) => (p as unknown as IRailsProject).chartableCost);
        makeObservable(this);
    }

    static selectedOnLoad = true;

    static aspect = .66;

    static get label() {
        return 'Summary';
    }

    static get title() {
        return 'Summary';
    }

    render() {
        const { length, residents, workers } = (getRootStore().app as RailsAppStore).outputs;

        const metrics: {[key:string]: string} = {
            "Scenario Total Cost": `$ ${roundCost(this.costView.cumulativeCurrent).toLocaleString()}`,
            "Total Residents": residents,
            "Total Workers": workers,
            "Park Build Length": length,
        };

        return (
            <div className="DashiChart SummaryChart" style={this.props.style}>
                <ul>
                    {Object.entries(metrics).map(([metric, value]) => (
                        <li key={metric}>
                            <h5>{metric}</h5>
                            <p>{value}</p>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

}


export default Summary;
