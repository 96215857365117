import { override } from 'mobx';
import { observer } from 'mobx-react';

import { getRootStore } from '../../../core';
import PieChart from '../../../core/ui/charts/PieChart';

import RailsAppStore from '../stores/AppStore';


@observer
class Race extends PieChart {

    static selectedOnLoad = true;

    static get label() {
        return "Race";
    }

    static get title() {
        return "Race";
    }

    get colors() {
        return {
            "Hispanic / Latino": '#F9A57B',
            "White": "#514E98",
            "Black": "#E5C275",
            "Another Race / Multiracial": "#87CFC2",
            "Asian": "#D479B1",
        };
    }

    @override
    get data() {
        const { race } = (getRootStore().app as RailsAppStore).outputs;
        return [race];
    }

}

export default Race;
