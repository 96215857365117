import { time } from 'react-timeline';

export function randomString(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export function duration(a: number, b: number) {
    return Math.floor((b - a) / time.MONTH);
}

export function reduceById<T extends { id: string }>(arr: T[]): { [key: string]: T } {
    return [ ...arr ].reduce((a,b) => {
        a[b.id] = b;
        return a;
    }, {} as { [key: string]: T });
}


export function add(accumulator: number, a: number) {
    return accumulator + a;
}

export function cumulative(arr: number[]) {
    return arr.map((v, i, data) => {
        return data.slice(0, i + 1).reduce(add)
    });
}


/**
 * Chunks a larger array into smaller arrays of a given size
 *
 * @param arr - the input array to be chunked
 * @param size - the size of each chunk
 * @returns a new 2D array with each internal array being of length 'size'
 *
 */
export function chunk(arr: number[], size: number): number[][] {
    return [...Array(Math.ceil(arr.length / size))].map((_, i) =>
        arr.slice(size * i, size + size * i)
    );
}

export function validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
