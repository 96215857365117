import { observer } from 'mobx-react';
import { CheckboxSlider, RadioSlider } from '@strategies/react-form';
import { useDashiStores } from '../../../../core';

import TrendingUpIcon from '../../../../core/ui/icons/TrendingUp';
import { fixedRectStyle } from "../../../../core/stores/LayoutStore";


export default observer(function ChartsPanel() {
    const { app } = useDashiStores();
    const { charts, layout, outputs, ui } = app;

    const isDisabled = chart => (
        chart.isDisabled
        || (
            layout.innerChartArea.size.width < chart.requiredWidth + layout.requiredChartsWidth
            && !ui.isChartSelected(chart)
        )
    );

    return (
        <div className="ChartsPanel" style={fixedRectStyle(layout.chartsPanel)}>
            <div className={`panel cPanel ${!ui.chartsPanelIsOpen ? 'panel--closed' : ''}`}>
                <div className="panel__header">
                    <div className="panel-toggle">
                        <button onClick={() => ui.setChartsPanelOpen(!ui.chartsPanelIsOpen)}>
                            <TrendingUpIcon />
                        </button>

                        {ui.chartsPanelIsOpen && <h2>Analysis</h2>}
                    </div>

                    {ui.chartsPanelIsOpen && (
                        <div className="chartFilter">
                            {!ui.chartsPanelIsOpen || layout.chartsPanel.size.width < 800 ? <>

                            </> : <>
                                {charts.map(Chart => (
                                    <div key={Chart.label} className={"filter indChartsLabel" + (isDisabled(Chart) ? ' disabled' : '')}>
                                        <label htmlFor={Chart.label}>
                                            <input
                                                id={Chart.label}
                                                name={Chart.label}
                                                type="checkbox"
                                                value={Chart.label}
                                                disabled={isDisabled(Chart)}
                                                checked={ui.isChartSelected(Chart)}
                                                onChange={() => ui.setChartSelection(Chart, !ui.isChartSelected(Chart))}
                                            />

                                            <div className={"chartTitle"}>
                                                {Chart.label}
                                            </div>
                                        </label>
                                    </div>
                                ))}
                            </>}

                            <div className="filter filter-toggle">
                                <RadioSlider
                                    options={['5', '10'].map(x => `${x} min`)}
                                    value={`${outputs.isoTime} min`}
                                    onChange={val => outputs.setIsoTime(val.replace(' min', ''))}
                                />
                            </div>

                            <div className="filter filter-toggle">
                                SELECTED ONLY
                                <CheckboxSlider
                                    value={app.activeBySelection}
                                    onChange={val => app.setActiveBySelection(val)}
                                />
                            </div>
                        </div>
                    )}
                </div>

                {ui.chartsPanelIsOpen && (
                    ((!app.activeBySelection && app.includedProjects.length > 0) || (app.activeBySelection && app.selectedProjects.length > 0)) ? (
                        <div className="charts" style={{ height: layout.innerChartArea.size.height }}>
                            {layout.charts.map(slot => (
                                slot.chart && (
                                    <div key={slot.chart.label} className="ChartWrapper" style={slot.rect || {}}>
                                        <slot.chart
                                        />
                                    </div>
                                )
                            ))}
                        </div>
                    ) : (
                        <div className="charts-placeholder">
                            <div>
                                No {app.activeBySelection && "Selected "}Projects
                            </div>
                        </div>
                    )
                )}
            </div>

        </div>
    );
});
