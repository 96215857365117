import App from './ui/App';
import '../philly-rails/index.scss';
import { bootstrap } from '../../core';
import firebaseConfig from './firebaseConfig';

import config from '../philly-rails/config';
import app from '../philly-rails/stores/AppStore';
import persist from '../philly-rails/stores/PersistStore';
import colorizer from '../philly-rails/stores/ColorizerStore';

bootstrap(firebaseConfig, config, { persist, app, colorizer });

export default App;
