import { observer } from 'mobx-react';
import Modal from '@strategies/react-modal';

import { useDashiStores } from '../../../core';
import Logo from '../../../core/ui/assets/logo.svg';
import Chevron from '../../../core/ui/icons/Chevron';


export default observer(function WelcomeModal() {
    const { app } = useDashiStores();

    return (
        <Modal className={`WelcomeModal ${app.ui.welcomeModalIsOpen ? 'active' : ''}`} onClose={() => app.ui.closeWelcomeModal()}>
            <h1>
                The Rail Park Implementation and Capital Planning
            </h1>

            <img src={Logo} alt="Dashi Logo" title="Dashi by Sasaki" />

            <p>
                Dashi is a Sasaki platform for flexible implementation and capital planning metrics dashboard examining the relationship between analytical and operational aspects.
            </p>

            <button className="primary" onClick={() => app.ui.closeWelcomeModal()}>
                Go to Dashboard
                <Chevron />
            </button>
        </Modal>
    );
});
