import {computed, makeObservable, observable} from "mobx";
import {IProjectData, TimelineStatus} from "./Project";
import {getRootStore} from "../index";
import {add, chunk, cumulative} from "../util";

export default class MetricView {

    valueGetter: (project: IProjectData) => number[];
    groupGetter?: (project: IProjectData) => string;

    constructor(valueGetter: (project: IProjectData) => number[], groupGetter?: (project: IProjectData) => string) {
        makeObservable(this);
        this.valueGetter = valueGetter;
        this.groupGetter = groupGetter;
    }

    @computed
    get chartableProjects() {
        const {app} = getRootStore();
        return app.activeBySelection ? app.selectedProjects : app.projects.filter(project => !project.isExcluded);
    }

    @computed
    get current() {
        const {app} = getRootStore();
        return this.totals[app.currentPeriod.i]
    }

    @computed
    get currentByType() {
        const {app} = getRootStore();
        return this.totalsByType[app.currentPeriod.i]
    }

    @computed
    get cumulativeCurrent() {
        const {app} = getRootStore();
        return this.cumulativeTotals[app.currentPeriod.i]
    }

    @computed
    get states(): number[] {
        return this.chartableProjects.map(project => project.timelineStatus === TimelineStatus.Completed ? 1 : 0)
    }

    @computed
    get totals(): number[] {
        console.log('@computed totals');
        const {app} = getRootStore();
        const rows = this.chartableProjects.map((project => this.valueGetter(project)))
        const totals: number[] = [];
        if (rows.length === 0) return totals;
        for (let i = 0; i < rows[0].length; i++) {
            let sum = 0;
            for (let j = 0; j < rows.length; j++) {
                sum += rows[j][i];
            }
            totals.push(sum);
        }
        return totals;
    }

    @computed
    get totalsClusterYear(): number[] {
        return chunk(this.totals, 12).map(months => months.reduce(add, 0));
    }

    @computed
    get cumulativeTotals(): number[] {
        return cumulative(this.totals);
    }

    @computed
    get cumulativeTotalsClusterYear(): number[] {
        return cumulative(this.totalsClusterYear);
    }

    @computed
    get totalsByType(): { [key: string]: number; }[] {
        const ans: { [key: string]: number; }[] = [];
        this.chartableProjects.forEach(project => {
            const values = this.valueGetter(project);
            const group = this.groupGetter ? this.groupGetter(project) : '';
            for (let i = 0; i < values.length; i++) {
                if (!ans[i]) {
                    ans[i] = {};
                }
                const elem = ans[i];
                if (!elem[group]) {
                    elem[group] = 0;
                }
                elem[group] += values[i];
            }
        })

        return ans;
    }

    @computed
    get totalsByTypeByYear(): { [key: string]: number[] } {
        return {};
    }
};
