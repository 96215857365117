import { action, computed, observable, makeObservable } from 'mobx';
import firebase from 'firebase/app';
import 'firebase/auth';

import { getRootStore } from '..';
import DashiStore from './DashiStore';


export default class DashiUserStore extends DashiStore {

    constructor() {
        super();

        makeObservable(this);
    }

    onAttachedToRootStore() {
        if (firebase.apps.length === 0) {
            firebase.initializeApp(getRootStore().config);
        }

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.setUser(user);
            }
            else {
                this.setUser(null);
            }
        });

    }

    @observable
    user: any = null;

    @computed
    get displayName() {
        return this.user ? this.user.displayName : '';
    }

    logout() {
        firebase.auth().signOut();
        window.location.reload();
    }

    @computed
    get isLoggedIn() {
        return this.user !== null;
    }

    @computed
    get isVerified() {
        return this.user && this.user.emailVerified;
    }

    @action
    setUser(user: any) {
        this.user = user !== null ? user : null;
    }

}
