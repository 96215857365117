import {computed, makeObservable, observable, override} from 'mobx';
import {observer} from 'mobx-react';
import {Chart} from "react-chartjs-2";
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import annotationPlugin from 'chartjs-plugin-annotation';
import {time} from 'react-timeline';
import {roundCost} from '../util';

import {
    Chart as ChartJS,
    registerables
} from 'chart.js';

import {getRootStore} from '../../../core';
import DashiChart, {DashiChartProps} from '../../../core/ui/charts/Chart';
import ChartHeader from '../../../core/ui/charts/ChartHeader/ChartHeader';
import RailsPersistStore from '../stores/PersistStore';
import {formatLineDataSet, getAnnotations} from '../../../core/ui/charts/chartFunctions';
import MetricView from "../../../core/models/MetricView";
import RailsProject, {IRailsProject} from "../models/Project";

ChartJS.register(annotationPlugin, ...registerables);


@observer
class Cost extends DashiChart {
    @observable
    protected metricView: MetricView;

    constructor(props: DashiChartProps) {
        super(props);
        this.metricView = new MetricView((p) => (p as unknown as IRailsProject).chartableCost);
        makeObservable(this);
    }

    x = 'TIME';
    y = '$';

    static selectedOnLoad = true;

    @computed
    get referenceLine() {
        return (getRootStore().persist as RailsPersistStore).fundraising;
    }

    @override
    static get isDisabled() {
        return !getRootStore().app.timelineEnabled;
    }

    @computed
    get timeIncrement() {
        return time.MONTH;
    }

    @computed
    get scrubber() {
        const {app, persist} = getRootStore();

        return {
            value: app.scrubber / this.timeIncrement,
            info: `$${roundCost(this.lineData[this.currentX]).toLocaleString()} | $${(persist as RailsPersistStore).fundraising.toLocaleString()}`,
        };
    }

    @computed
    get currentX() {
        const {app} = getRootStore();
        return app.currentPeriod.i;
    }

    @computed
    get xLabels() {
        const {startYear, periodScale, totalPeriods} = getRootStore().config;
        const months = Math.floor((totalPeriods * periodScale) / time.MONTH);

        return [...new Array(months)].map((_, i) => {
            if (i % 12 !== 0) return '';
            const year = i / 12;
            return `'${(year + startYear) - 2000}`
        });
    }

    @computed
    get lineData() {
        return this.metricView.cumulativeTotals;
    }

    @computed
    get barData() {
        return this.metricView.totals;
    }

    static get label() {
        return "Cost";
    }

    static get title() {
        return "Cost";
    }

    get colors() {
        return {
            line: 'rgba(165, 196, 14, 0.5)',
            bar: '#000',
        };
    }

    render() {
        const {height, width, style} = this.props;
        const {app} = getRootStore();

        const data = {
            labels: this.xLabels || app.periodLabels,
            datasets: [
                {
                    type: 'bar',
                    data: this.barData,
                    backgroundColor: this.colors.bar,
                },
                formatLineDataSet(this.colors.line, '', this.lineData),
            ],
        };

        const options = {
            plugins: {
                legend: {
                    display: false,
                },
                annotation: {
                    annotations: getAnnotations(this.scrubber, this.referenceLine),
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    title: {
                        text: this.x,
                        display: true,
                    },
                },
                y: {
                    stacked: true,
                    title: {
                        text: this.y,
                        display: true,
                    }
                },
            },
        };

        return (
            <div className="DashiChart CumulativeCostChart" style={style}>
                <ChartHeader title={this.static.title} data={null}/>

                <div className={'chartJsResponsiveParent'} style={{width, height}}>
                    <Chart
                        /* @ts-ignore */
                        data={data}
                        options={options}
                        /* @ts-ignore */
                        plugins={[ChartAnnotation]}
                    />
                </div>
            </div>
        );
    }

}


@observer
export class CostByYear extends Cost {
    constructor(props: DashiChartProps) {
        super(props);
        makeObservable(this);
    }

    static get label() {
        return "Cost By Year";
    }

    static get title() {
        return "Cost";
    }

    @override
    get timeIncrement() {
        return time.YEAR;
    }

    @override
    get currentX() {
        const {app} = getRootStore();
        return Math.floor(app.currentPeriod.i / 12);
    }

    @override
    get xLabels() {
        const {startYear, periodScale, totalPeriods} = getRootStore().config;
        const years = Math.floor((totalPeriods * periodScale) / time.YEAR);

        return [...new Array(years)].map((_, i) => `'${i + startYear - 2000}`);
    }

    @override
    get lineData() {
        return this.metricView.cumulativeTotalsClusterYear;
    }

    @override
    get barData() {
        return this.metricView.totalsClusterYear;
    }
}

export default Cost;
