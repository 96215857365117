import { createContext, useContext } from 'react';

import DefaultDashiConfig  from './config';
import FirebaseConfig from './types/FirebaseConfig';
import RootStore, {TopLevelStores} from './stores/RootStore';


let _context: any;
let _root: RootStore;

export function bootstrap(firebaseConfig: FirebaseConfig, config: any = {}, stores: TopLevelStores = {}) {
    _root = new RootStore({ ...firebaseConfig, ...DefaultDashiConfig, ...config }, stores);
    _root.attach();

    _context = createContext(_root);
}

export function getRootStore(): RootStore {
    return _root;
}

export function useDashiStores(): RootStore {
    return useContext(_context);
}
