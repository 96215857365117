import {useCallback, useEffect} from 'react';
import {reaction} from 'mobx';
import {observer} from 'mobx-react';
import Modal from '@strategies/react-modal';
import { Input, Label } from '@strategies/react-form';

import {useDashiStores} from '../../../../core';


let _cached;

export default observer(function GlobalSettingsModal() {
    const {app: { ui }, persist} = useDashiStores();

    useEffect(() => (
        reaction(
            () => ui.globalSettingsModalIsOpen,
            () => _cached = persist.fundraising
        )
    ));

    const cancel = useCallback(() => {
        persist.setFundraising(_cached);
        ui.setGlobalSettingsModalOpen(false);
    }, [persist, ui]);

    return (
        <Modal
            className={`GlobalSettingsModal ${ui.globalSettingsModalIsOpen ? 'active' : ''}`}
            onClose={() => ui.setGlobalSettingsModalOpen(false)}
        >
            <header>
                Global Settings and Goals

                <div className="tag">
                    Change and find out more information about global settings.
                </div>
            </header>

            <div className="content">
                <Input
                    type="number"
                    name="Fundraising Goal"
                    value={persist.fundraising}
                    onChange={val => persist.setFundraising(val)}
                    left="$"
                />

                <Label name="Cost Assumptions">
                    <div className="assumptions">
                        <p>
                            The costs associated with the Philly Rail Park Dashi Tool are planning level costs generated based on precedents identified throughout the country.  Comparable projects were selected for each of the project types (Tunnel, Hard Cut, Soft Cut, and Viaduct).  Costs associated with those projects were then escalated to 2022 dollars, converted based on regional cost index calculations, and then applied as unit prices over the area of each segment.  Note that these costs are not based on a specific design strategy for each segment; rather, they should be considered as an initial budget for planning purposes.  The costs include both hard costs (construction) and some soft costs, such as general conditions, design fees, and contingencies.  Not included are hazardous materials remediation or environmental costs, major structural repairs, replacement or removal of existing utilities, or land acquisition costs.
                        </p>
                    </div>
                </Label>
            </div>

            <div className="modal-buttons">
                <button className="secondary" onClick={cancel}>Cancel</button>
                <button className="primary" onClick={() => ui.setGlobalSettingsModalOpen(false)}>Save</button>
            </div>
        </Modal>
    );

});
