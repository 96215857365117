import {Block, time} from 'react-timeline';
import {autorun, computed, observable} from 'mobx';
import {getSnapshot, Model, model, modelAction, prop} from 'mobx-keystone';

import {getRootStore} from '..';
import DashiMetric from './Metric';
import DashiColorizerState from "./Colorizer";


export enum TimelineStatus {
    NotStarted = "NotStarted",
    Underway = "Underway",
    Completed = "Completed",
}

export interface IProjectData {
    id: string;
    mapped?: boolean;
    metrics?: { [key: string]: DashiMetric };
    name: string;
    type: string;
    timestamps: ProjectTimeline;
    isExcluded: boolean;
    timelineY?: number;
}

export type ProjectTimeline = {
    start: number;
    end: number;
    design: number;
}

type TimelineParameters = {
    start?: number;
    end?: number;
    design?: number;
};

type BlockEvent = {
    start: number;
    end: number;
    removed: boolean;
    selected: boolean;
    blockRight?: typeof Block;
    blockLeft?: typeof Block;
};


@model('dashi/Project')
class DashiProject extends Model({
    id: prop<string>(),
    name: prop<string>(''),
    timestamps: prop<ProjectTimeline>(),
    timelineY: prop<number>(0),
    type: prop<string>(),
    metrics: prop<any>(() => {
    }),
    mapped: prop<boolean>(true),
    isExcluded: prop<boolean>(false),
    _campus: prop<string>(''),
}) implements IProjectData {

    protected onInit() {
        this.constructBlocks();

        // Keep Alive
        autorun(() => this.color);
    }

    @modelAction
    constructBlocks() {
        const {config, persist} = getRootStore();

        const block = this.block ? this.block : new Block(this.$modelId, this.timestamps.start, this.timestamps.end, this.timelineY);
        const designBlock = new Block(this.$modelId, this.timestamps.design, this.timestamps.start, this.timelineY);

        if (!this.mapped) {
            block.setClassName("unmapped");
            designBlock.setClassName("unmapped");
        }

        block.onChange = ({selected, start, end, blockLeft, removed}: BlockEvent) => {
            if (removed) {
                return persist.remove(this);
            }

            this.setTimestamps({start, end, design: blockLeft.start});

            if (!designBlock.selected && this.selected !== selected) {
                this.setSelected(selected, false);
            }
        }

        designBlock.onChange = ({end, selected, start, blockRight, removed}: BlockEvent) => {
            if (removed) {
                return persist.remove(this);
            }

            this.setTimestamps({design: start, start: end, end: blockRight.end});

            if (!block.selected && this.selected !== selected) {
                this.setSelected(selected, false);
            }
        };

        block.setName(this.name);
        block.setColor(this.color);
        designBlock.setColor(config.designPhaseColor);

        block.setBlockLeft(designBlock);
        designBlock.setBlockRight(block);

        this.blocks = [block, designBlock];
    }

    @modelAction
    setMetric(name: string, payload: any) {
        this.metrics[name] = payload;
    }

    @observable
    blocks: (typeof Block)[] = [];

    @computed
    get block() {
        return this.blocks.length > 0 ? this.blocks[0] : null;
    }

    @observable
    snapshots: any[] = [];

    @computed
    get periods() {
        const {periodScale} = getRootStore().config;
        const {design, start, end} = this.timestamps;

        const periods = {
            design: Math.floor(design / periodScale),
            start: Math.floor(start / periodScale),
            end: Math.floor(end / periodScale),
        };

        return periods;
    }

    @computed
    get phase() {
        const {phases} = getRootStore().config;

        for (let phase in phases) {
            if (this.timestamps.start >= phases[phase][0] && this.timestamps.start < phases[phase][1]) {
                return phase;
            }
        }

        return undefined;
    }

    @modelAction
    snapshot() {
        this.snapshots.push(getSnapshot(this));
    }

    @computed
    get escalation() {
        const {escalation} = getRootStore().config;
        const years = Math.max(Math.floor(this.timestamps.start / time.YEAR) - 1, 0);

        let compounded = 0;
        for (let year = 0; year < years; year++) {
            compounded = -1 + (1 + compounded) * (1 + escalation);
        }

        return 1 + compounded;
    }

    computeTimelineStatus(timelineEnabled: boolean, start: number, end: number, scrubber: number): TimelineStatus {
        if (!timelineEnabled) return TimelineStatus.Completed;

        if (scrubber >= start) {
            return scrubber <= end ? TimelineStatus.Underway : TimelineStatus.Completed;
        }

        return TimelineStatus.NotStarted;
    }

    @computed
    get timelineStatus(): TimelineStatus {
        const {app} = getRootStore();
        const {scrubber} = app;
        const {start, end} = this.timestamps;
        return this.computeTimelineStatus(app.timelineEnabled, start, end, scrubber);
    }

    @computed
    get color() {
        const {config, app} = getRootStore();

        if (this.isExcluded) {
            return '#dfdfdf';
        }

        // @ts-ignore
        const color = config[config.colorBy[app.colorMode]][this[app.colorMode]] || '#ff00ff';//highlight in magenta (this color should never show)

        if (this.block) {
            this.block.setColor(color);
        }

        return color;
    }

    @computed
    get colorizerState(): DashiColorizerState {
        const {color, timelineStatus, isExcluded, includedInCharts, selected, filteredOut} = this;

        return new DashiColorizerState({color, timelineStatus, isExcluded, includedInCharts, selected, filteredOut});
    }

    @observable
    selected: boolean = false;

    @computed
    get underway() {
        return this.timelineStatus === TimelineStatus.Underway;
    }

    @computed
    get includedInCharts() {
        const {app} = getRootStore();
        if (app.activeBySelection) {
            return this.selected;
        }
        return !this.isExcluded && !this.filteredOut && this.timelineStatus === TimelineStatus.Completed;
    }

    @computed
    get filteredOut(): boolean {
        const {app} = getRootStore();
        return app.projects.indexOf(this) === -1;
    }

    @computed
    get reasonWhyFilteredOut(): string {
        const {app} = getRootStore();

        let reason = '';
        Object.entries(app.filters).forEach(([attr, values]) => {
            // @ts-ignore
            let projValue = this[attr];
            if (values.indexOf(projValue) !== -1) {
                reason = `${attr}: ${projValue}`;
            }
        });

        return reason;
    }

    @computed
    get visible() {
        return true;
    }

    @modelAction
    setCampus(campus: string) {
        this._campus = campus;
    }

    @modelAction
    setName(name: string) {
        this.name = name;
        this.block.setName(name);
    }

    @modelAction
    setIsExcluded(value: boolean) {
        this.isExcluded = value;
    }

    @modelAction
    setTimestamps(timestamps: TimelineParameters) {
        this.timestamps = {...this.timestamps, ...timestamps};

        this.blocks[1].setStart(this.timestamps.design);
        this.blocks[0].setStart(this.timestamps.start);
        this.blocks[0].setEnd(this.timestamps.end);
    }

    @modelAction
    setSelected(selected: boolean = true, updateBlock: boolean = true) {
        this.selected = selected;

        if (updateBlock) {
            this.block.setSelected(selected);
        }
    }

    @modelAction
    setTimelineY(y: number) {
        this.timelineY = y;
        this.blocks.forEach((block: typeof Block) => block.setY(y));
    }

    @modelAction
    setType(type: string) {
        this.type = type;
    }

    @modelAction
    undo() {
        if (this.snapshots.length > 0) {
            const snapshot: any = this.snapshots.pop();

            this.setName(snapshot.name);
            this.setCampus(snapshot.campus);
            this.setType(snapshot.type);
            this.setTimestamps(snapshot.timestamps);

            Object.keys(this.metrics).forEach((metric: string) => {
                this.setMetric(metric, snapshot.metrics[metric]);
            });
        }
    }

    @computed
    get startPeriod() {
        const {config} = getRootStore();
        return Math.floor(this.timestamps.start / config.periodScale);
    }

    @computed
    get designPeriod() {
        const {config} = getRootStore();
        return Math.floor(this.timestamps.design / config.periodScale);
    }

    @computed
    get endPeriod() {
        const {config} = getRootStore();
        return Math.floor(this.timestamps.end / config.periodScale);
    }

}


export default DashiProject;
