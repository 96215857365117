import { computed, makeObservable, override } from 'mobx';

import HorizontalBarChart from '../../../core/ui/charts/HorizontalBarChart';


export default class JobsChart extends HorizontalBarChart {

    constructor(props: any) {
        super(props);

        makeObservable(this);
    }

    x = "% OF JOBS";
    y = "JOB CATEGORIES";

    static selectedOnLoad = true;

    get options() {
        return {
            scales: {
                x: {
                    max: 70,
                },
            },
        };
    }

    @computed
    get input(): {[key:string]: number} {
        return {};
    }

    @override
    get data() {
        const data = { ...this.input };

        const total = Object.values(data).reduce((a,b) => a + b, 0);
        Object.entries(data).forEach(([category, value]) => {
            data[category] = 100 * value / total
        });

        return [data];
    }

}
