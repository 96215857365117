import DashiConfig from '../config';
import FirebaseConfig from '../types/FirebaseConfig';
import DashiStore from './DashiStore';
import DashiAppStore from './AppStore';
import DashiCellStore from './CellStore';
import DashiColorizerStore  from './ColorizerStore';
import DashiPersistStore from './PersistStore';
import DashiUserStore from './UserStore';


export type TopLevelStores = {
    app?: typeof DashiAppStore;
    persist?: typeof DashiPersistStore;
    user?: typeof DashiUserStore;
    cells?: typeof DashiCellStore;
    colorizer?: typeof DashiColorizerStore;
};


type RootConfig = typeof DashiConfig & FirebaseConfig;

export default class RootStore {

    config: RootConfig;
    app: DashiAppStore;
    cells: DashiCellStore;
    colorizer: DashiColorizerStore;
    persist: DashiPersistStore;
    user: DashiUserStore;

    constructor(config: RootConfig, stores: TopLevelStores) {
        this.config = config;

        this.app = stores.app ? new stores.app({}) : new DashiAppStore({});
        this.cells = stores.cells ? new stores.cells() : new DashiCellStore();
        this.colorizer = stores.colorizer ? new stores.colorizer() : new DashiColorizerStore();
        this.persist = stores.persist ? new stores.persist({}) : new DashiPersistStore({});
        this.user = stores.user ? new stores.user() : new DashiUserStore();
    }

    attach() {
        [
            this.persist, 
            this.user,
            this.app, 
            this.app.ui,
            this.app.layout,
            this.colorizer, 
            this.cells,
        ].forEach((store: DashiStore) => {
            store.onAttachedToRootStore();
        });
    }

};
